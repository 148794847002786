<template>
  <section>
    <section>
      <pm-Breadcrumb :home="home" :model="items_bread" />

      <div class="row">

        <div class="col-md-12">

            <div class="row">
              <div class="col-md-7">
                <h2 class="titulo-links-escolha">Períodos</h2>
              </div>
              <div class="col-md-5"  v-if="situacaoAno && acesso.cadastrar">
                <pm-Button type="button" style="float: right;margin-top: 30px;"
                    @click="cadastrarSerie()" label="CADASTRAR PERÍODOS"
                    class="p-button-success"
                ></pm-Button>
                <!-- <va-button class="mr-2 mb-2" size="small" color="success" @click="cadastrarSerie()" style="float:right;"> {{ $t('Registrar') }}</va-button>-->
              </div>
            </div>



            <div class="pm-card" v-if="acesso.visualizar">
              <pm-DataTable :value="item"   dataKey="id" :rowHover="true" filterDisplay="menu"
                 responsiveLayout="scroll">
                  <template #header>
                    <div class="row" style="margin-top:5px; margin-bottom:5px;">
                      <div class="col-md-2">
                          <select class="p-inputtext p-component" style="appearance: revert !important;width: 100% !important;" v-model="num">
                            <option value="0">Todas</option>
                            <option value="1">Fundamental I</option>
                            <option value="2">Fundamental II</option>
                            <option value="3">ENSINO REGULAR NOTURNO (ERN)</option>
                            <option value="4">ENSINO DE JOVENS E ADULTOS (EJA)</option>
                          </select>
                      </div>
                      <div class="col-md-3">
                          <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <pm-InputText v-model="pesquisa" @input="pesquisar" placeholder="Pesquisar Período "/>
                          </span>
                      </div>
                    </div>
                  </template>
                  <pm-Column field="nome" sortable header="NOME DO PERÍODO" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
                  <pm-Column field="etapa" sortable header="NOME DO SEGMENTO" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
                  <pm-Column field="acao" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
                    <template #body="{data}">
                      <pm-Button type="button" @click="editarSerie(data.acao.id,0)" icon="pi pi-pencil"
                         label="Editar" title="Editar" v-if="situacaoAno && acesso.editar" class="p-button-sm btn-color;table_style">
                      </pm-Button>
                      <pm-Button type="button" @click="editarSerie(data.acao.id,1)"  v-if="situacaoAno && data.acao.tipo == 3 &&  acesso.deletar"
                         label="Painel das Perguntas" title="Painel das Perguntas" style="margin-left:5px"  class="p-button-sm p-button-warning btn-color;table_style">
                      </pm-Button>
                    </template>
                  </pm-Column>
              </pm-DataTable>
              <div class="p-paginator p-component p-paginator-bottom" >
                <div class="p-paginator-pages" style="display: inline-flex;">
                  <div  v-for="n in links" :key="n">
                    <button class="p-paginator-next p-paginator-element p-link" @click="paginas(n)" v-if="!n.active"
                    style="border: 0px solid #ebebeb;border-radius: 0;">
                      <p class="p-paginator-icon " v-html="n.label"  style="margin-bottom:0px !important;"></p>
                    </button>
                    <button class="p-paginator-page p-paginator-element p-link p-highlight" @click="paginas(n)" v-if="n.active" style="border-radius: 0;">{{n.label}}</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  </section>
</template>


<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import { Verificar } from "@/class/verificar.js";
import { SeriesEscolar } from "@/class/serie.js";
import Util from "@/utils/rota";

export default defineComponent({
  data () {
    const columns_series = [
      /*{ key: 'id' },*/
      {key: 'nome', sortable: true },
      {key: 'etapa', label:'Segmento' ,sortable: true },
      { key: 'acao', label:'Ação'},

    ]

    return {
      funcao:0,
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Períodos'},
      ],
      situacaoAno:1,
      filter: '',
      item:[],
      links:[],
      columns_series,
      perPage: 20,
      currentPage: 1,
      pesquisa:null,
      num:0,
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      }
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.item.length / this.perPage)
          : this.item.length
    },
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.logado();
    await this.listarTudo();
    let acesso = await Verificar.AcessoLocal(4);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
  methods:{
    async logado(){
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    async pesquisar(){
      if(this.pesquisa != null && this.pesquisa != ""){
        let data = await SeriesEscolar.pesqSeriesCompleto(this.pesquisa,this.num);
        this.item = [];
        this.item = [];
        this.links = [];
        for (const el of data.data) {
            let novo = {
                id: el.id,
                nome:el.nome,
                segmento_id: el.segmento_id,
                etapa: el.segmento.nome,
                situacao: el.situacao,
                acao:{
                  tipo: el.tipo,
                  id: el.id,
                }
            };
          this.item.push(novo);
        }
        this.item.reverse();
      }else{
        this.listarTudo();
      }
    },

    async paginas(n){
      if(n.url != null){
        if(!n.active){
          let res = await axios.get(n.url);
          this.item =[];

          this.links = res.data.links;
          for (const el of res.data.data) {
            let novo = {
                id: el.id,
                nome:el.nome,
                segmento_id: el.segmento_id,
                etapa: el.segmento.nome,
                situacao: el.situacao,
                acao:{
                  tipo: el.tipo,
                  id: el.id,
                }
            };
            this.item.push(novo);
          }
          this.item.reverse();
        }
      }
    },

    cadastrarSerie() {
      if(this.funcao == 1 || this.funcao == 2 || this.funcao == 7){
        this.$router.push({ name: "serieCadastro" });
      }else{
        this.$router.push({ name: "coordenacao-serie-add" });
      }
    },
    editarSerie(id,exb) {
      if(this.funcao == 1 || this.funcao == 2 || this.funcao == 7){
        this.$router.push({ name: "serieEditar", params: { id: id, exibir:exb } });
      }else{
        this.$router.push({ name: "coordenacao-serie-edit", params: { id: id, exibir:exb } });
      }
    },
    async listarTudo() {
      let data = await SeriesEscolar.obtemTodosPG(this.currentPage)
      this.links = data.data.links;
      for (const el of data.data.data) {
        let novo = {
            id: el.id,
            nome:el.nome,
            segmento_id: el.segmento_id,
            etapa: el.segmento.nome,
            situacao: el.situacao,
            acao:{
              tipo: el.tipo,
              id: el.id,
            }
        };
        this.item.push(novo);
      }
      this.item.reverse();
    },

  },
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>
